import React from "react"
import Modal from "react-modal"
import { ThemeProvider } from "styled-components"
import { GlobalStyle } from "./src/styles/GlobalStyle"
import defaultTheme from "./src/styles/DefaultTheme"
import "./src/styles/normalize.css"
import "./src/styles/webfonts.css"

if (typeof document !== 'undefined') {
  document.getElementById('___gatsby').setAttribute('ontouchstart', '');
}

Modal.setAppElement('#___gatsby');

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={defaultTheme}>
    <GlobalStyle />
    {element}
  </ThemeProvider>
)

import { createGlobalStyle } from "styled-components"
import { transparentize } from "polished"

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    touch-action: manipulation;
    -webkit-touch-callout: none;
  }

  html {
    height: 100%;
    background-color: ${props => props.theme.color.background.page};
    font-family: ${props => props.theme.font.family.normal};
    font-size: ${props => props.theme.font.size.base};
    scroll-behavior: smooth;
    overscroll-behavior-y: none;
  }

  body {
    height: 100%;
    min-width: ${props => props.theme.contentWidth.min};
    color: ${props => props.theme.color.text.primary};
    text-size-adjust: 100%;
    overscroll-behavior-y: none;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  p {
    margin: 0.5em 0;
  }

  a {
    color: ${props => props.theme.color.link.normal};
    &:visited {
      color: ${props => props.theme.color.link.normal};
    }
    @media (hover: hover) {
      &:hover {
        color: ${props => props.theme.color.link.hover};
      }
      &:active {
        color: ${props => props.theme.color.link.active};
      }
    }
    @media (hover: none) {
      &:active {
        color: ${props => props.theme.color.link.hover};
      }
    }
  }

  // gatsby
  #___gatsby {
    height: 100%;
  }

  #gatsby-focus-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  // react-modal
  .ReactModal__Overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: ${props => props.theme.contentWidth.min};
    background-color: ${transparentize(0.25, '#000')} !important;
    z-index: ${props => props.theme.zIndex.modal};
  }

  .ReactModal__Content {
    position: relative !important;
    display: flex;
    flex-direction: column;
    max-width: 90vw;
    min-width: calc(${props => props.theme.contentWidth.min} * 0.9);
    inset: auto !important;
  }
`;
